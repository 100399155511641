<template>
  <div>
    <b-row class="vertical-center">
      <b-col lg="2"></b-col>
      <b-col>
        <b-card v-if="!isLoading" class="quiz-overview-card  mb-1 p-1">
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex">
              <div class="mr-1">
                <b-avatar rounded="lg" :variant="taskType(contentType).variant">
                  <i class="card-icon " :class="taskType(contentType).icon" />
                </b-avatar>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-column ">
                    <span class="text-muted ">
                      {{ taskType(contentType).title }}
                    </span>
                    <h6 class="font-weight-bolder small-gap-bottom">
                      {{ quizOverview.name }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <!-- Score -->
            <div class="d-flex align-items-center">
              <span>Nilai</span>
              <b-badge variant="success" class="badge-glow p-1 ml-1">
                <h4 class="text-white m-0">
                  {{ quizOverview.student_exam_grades[0].score }}
                </h4>
              </b-badge>
            </div>
          </div>
          <b-row class="mt-2">
            <b-col md="3">
              <!-- Teacher Info -->
              <div>
                <h6 class="font-weight-bolder small-gap-bottom">
                  Guru Kelas
                </h6>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="quizOverview.classroom.teacher.avatar"
                      :text="avatarText(quizOverview.classroom.teacher.name)"
                      variant="light-primary"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ quizOverview.classroom.teacher.name }}
                  </span>
                  <small class="text-muted">{{
                    quizOverview.classroom.teacher.school.name
                  }}</small>
                </b-media>
              </div>
            </b-col>

            <b-col md="3">
              <!-- Class Info -->
              <div>
                <h6 class="font-weight-bolder  small-gap-bottom">
                  Kelas
                </h6>
                <span>
                  {{ quizOverview.classroom.name }}
                </span>
              </div>
            </b-col>

            <b-col md="3">
              <div>
                <h6 class="font-weight-bolder  small-gap-bottom">
                  Tipe Kuis
                </h6>
                <span>
                  {{
                    taskType(contentType)
                      .title.split(' : ')
                      .pop()
                  }}
                </span>
              </div>
            </b-col>

            <b-col md="3">
              <!-- Quiz Datetime-->
              <div>
                <h6 class="font-weight-bolder  small-gap-bottom">
                  Waktu Kuis
                </h6>
                <span>{{ toHumanDate(quizOverview.start_date) }}</span>
              </div>
            </b-col>

            <b-col>
              <!-- Exam Step Info -->
              <div>
                <h6 class="font-weight-bolder mt-1 small-gap-bottom">
                  Kuis Selesai 🎉
                </h6>
                <ul class="regular-ul">
                  <li>
                    Jika nilai belum keluar atau belum ada berarti kuis masih
                    dalam proses penilaian
                  </li>
                  <li>
                    Silahkan lihat notifikasi secara berkala untuk mengetahui
                    nilai apabila nilai telah tersedia
                  </li>
                </ul>
              </div>
            </b-col>

            <b-col md="12" class="pt-1 ">
              <div class="d-flex justify-content-start">
                <b-button
                  variant="outline-primary"
                  :to="{
                    name: checkAuthorizeRole('classroom-detail-task'),
                    params: { id: classroomId, contentId: contentId },
                  }"
                >
                  <i class="align-middle ti ti-arrow-left mr-1" />
                  <span class="align-middle">Kembali</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="2"></b-col>
    </b-row>

    <div
      v-if="isLoading"
      class="d-flex flex-column align-items-center vertical-center"
    >
      <b-spinner variant="primary" />
      <span class="small-gap-top">Memuat Hasil Kuis</span>
    </div>
  </div>
</template>

<script>
// *Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BMedia,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'

// *Utils
import client from '@/libs/http/axios-config'
import { avatarText, taskType, toHumanDate } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BMedia,
    BSpinner,
    BBadge,
  },
  data() {
    return {
      // State
      isLoading: false,
      // Basic Data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      contentType: 'multiple',

      // Exam Data
      quizOverview: {},
      isAcceptTerm: false,
      questionCount: 0,
    }
  },
  async mounted() {
    await client
      .get(
        `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams`
      )
      .then((response) => {
        this.quizOverview = response.data.data.taskExam[0]
        this.contentType = response.data.data.examQuestion.type

        this.questionCount = JSON.parse(
          response.data.data.taskExam[0].session_exam_students[0].data
        ).quiz_overview.quiz_question.length

        console.log(this.questionCount)

        this.isLoading = false
      })
  },
  methods: {
    avatarText,
    taskType,
    toHumanDate,
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss">
.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.regular-ul {
  padding-left: 1.5rem;
}

.quiz-overview-card {
  li {
    line-height: 1.6rem;
  }
}

.vertical-center {
  margin-top: calc(12vh + 2rem);
}
</style>
